import ShimmerBanner from "./shimmers/ShimmerBanner";
import useGetImages from "../hooks/useGetImages";

const BannerList = ({ category, id, language }) => {
  const { image: uri, isLoading } = useGetImages(
    "category_pictures",
    id,
    category
  );

  if (!category || !uri) {
    return null;
  }

  return (
    <div className="container">
      {isLoading ? (
        <div className="flex gap-4 md:gap-8 mb-2">
          {Array.from({ length: 1 }).map((_, i) => (
            <ShimmerBanner key={i} />
          ))}
        </div>
      ) : (
        <>
          <img
            className="block w-full h-32 rounded-md"
            src={uri}
            alt="Images"
          />
          <h1 className="font-bold text-xl text-zinc-600 text-center my-2">
            {category}
          
          </h1>
        </>
      )}
    </div>
  );
};
export default BannerList;
